var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-2"},[_c('div',{style:({ width: !_vm.noInnerWidth ? _vm.width : 'auto' })},[_c('div',[_c('b-table',{attrs:{"busy":_vm.isLoading,"head-variant":"light","no-local-sorting":true,"items":_vm.items,"fields":_vm.getAllFields,"current-page":_vm.currentPage,"per-page":"0","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"sort-direction":_vm.sortDirection,"sticky-header":_vm.height,"no-border-collapse":"","responsive":"","show-empty":"","empty-text":"Non ci sono informazioni da mostrare","small":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"sort-changed":_vm.sortingChanged},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('base-icon',{attrs:{"name":"loading","width":"35","height":"35"}}),_vm._v(" "),_c('p',[_vm._v("Operazione in corso...")])],1)]},proxy:true},{key:"cell(actions)",fn:function(row){return [_c('div',{staticClass:"action-buttons"},[(_vm.actions.includes('edit'))?_c('b-button',{staticClass:"mt-1 mr-1",attrs:{"size":"sm","variant":"lisaweb","title":"Modifica","disabled":_vm.checkIsImported(row.item)},on:{"click":function($event){return _vm.onEdit(row.item.id)}}},[_c('b-icon',{attrs:{"icon":"pencil-square"}})],1):_vm._e(),_vm._v(" "),(
                _vm.actions.includes('destroy') &&
                [2].includes(row.item.status_correspondence.value)
              )?_c('b-button',{staticClass:"mt-1 mr-1",attrs:{"size":"sm","variant":"lisaweb","title":"Elimina"},on:{"click":function($event){return _vm.onDestroy(row.item.id)}}},[_c('b-icon',{attrs:{"icon":"trash"}})],1):_vm._e()],1)]}},{key:"cell(domicilio)",fn:function({ item }){return [_c('span',{domProps:{"innerHTML":_vm._s(
              _vm.toRelationString(
                'correspondences',
                item,
                ['ADDR'],
                [item.status_correspondence.value]
              )
            )}})]}},(_vm.items.length)?{key:"cell(city)",fn:function({ item }){return [_vm._v("\n          "+_vm._s(item.attributables.CITY2
              ? `${item.attributables.CITY2} - ${
                  _vm.$store.state.auth.countries.find(
                    (e) => e.id == item.attributables.NOR
                  ).title
                }`
              : _vm.$store.state.auth.councils.find(
                  (e) => e.id == item.attributables.CITY
                ).title)+"\n        ")]}}:null,{key:"row-details",fn:function(row){return [_c('b-card',_vm._l((_vm.customized(row.item)),function(value,key){return _c('b-row',{key:key,staticClass:"mb-2"},[_c('b-col',{staticClass:"text-sm-right",attrs:{"sm":"3"}},[_c('b',[_vm._v(_vm._s(_vm._f("capitalize")(key))+":")])]),_vm._v(" "),_c('b-col',[_vm._v(_vm._s(value))])],1)}),1)]}}],null,true)})],1),_vm._v(" "),_c('b-row',[_c('div',{staticClass:"show-text"},[_c('p',[_vm._v("Mostra")])]),_vm._v(" "),_c('div',{staticClass:"group"},[_c('b-form-group',{attrs:{"label-for":"per-page-select","label-cols-sm":"3","label-cols-md":"","label-cols-lg":"3","label-size":"sm","md":"auto"}},[_c('b-form-select',{staticStyle:{"width":"68px"},attrs:{"id":"per-page-select","options":_vm.pageOptions,"size":"sm","disabled":!_vm.items.length},on:{"change":function($event){return _vm.onPageChange(1)}},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1)],1),_vm._v(" "),_c('div',{staticClass:"page"},[_c('p',[_vm._v("di "+_vm._s(_vm.totalItems)+" per pagina")])]),_vm._v(" "),(_vm.totalItems)?_c('div',{staticClass:"pagination"},[_c('b-pagination',{attrs:{"limit":"3","total-rows":_vm.totalItems,"per-page":_vm.perPage,"size":"sm"},on:{"change":_vm.onPageChange},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }