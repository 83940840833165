<template>
  <div>
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading" class="mx-1">
        <h6>Titoli da incassare</h6>
        <export-options
          :exportUrl="exportUrl"
          :repository="repository"
          :resource="resource"
          :tableRef="$refs[tableRef]"
          :filter="filter"
          :options="[
            {
              code: 'EXPOTICLI',
              label: null,
              formats: ['csv', 'pdf'],
            },
          ]"
        ></export-options>
        <insurance-ancillaries
          :isTableInModal="true"
          infomodalName="T1"
          :fields="fields"
          :repository="repository"
          :resource="resource"
          :filterName="filterName1"
          ref="InsuranceAncillaryDetailTableRef1"
          :onlyActions="['infomodal']"
          noInnerWidth
          sortField="delay_until"
          :filterOn="{
            byRelations: ['byBroker', 'byBookEntry', 'byInsurerParticipant'],
          }"
          @rowSelector="onAddBookEntry"
          @reloadTables="onReloadTables"
          includeRowSelector
          noInputPaginator
        ></insurance-ancillaries>
        <h6>Altri Titoli</h6>
        <export-options
          :exportUrl="exportUrl"
          :repository="repository"
          :resource="resource"
          :tableRef="$refs[tableRef]"
          :filter="filter"
          :options="[
            {
              code: 'EXPOATCLI',
              label: null,
              formats: ['csv', 'pdf'],
            },
          ]"
        ></export-options>
        <insurance-ancillaries
          :isTableInModal="true"
          infomodalName="T2"
          :fields="fields"
          :repository="repository"
          :resource="resource"
          :filterName="filterName2"
          ref="InsuranceAncillaryDetailTableRef2"
          :onlyActions="['infomodal']"
          noInnerWidth
          sortField="delay_until"
          :filterOn="{
            byRelations: ['byBroker', 'byBookEntry', 'byInsurerParticipant'],
          }"
          @rowSelector="onAddBookEntry"
          @reloadTables="onReloadTables"
          includeRowSelector
          noInputPaginator
        ></insurance-ancillaries>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import ExportOptions from "@/components/ExportOptions";
import InsuranceAncillaries from "@/components/tables/InsuranceAncillaries";
import { toLocaleCurrency } from "@/utils/strings";
import { toLocaleDate } from "@/utils/dates";
import { mapActions } from "vuex";
import { getDictionary } from "@/utils/dictionary";

export default {
  name: "InsuranceAncillary",
  props: {
    resourceId: Number,
  },
  data() {
    return {
      exportUrl: null,
      isLoading: false,
      filter: {
        filterInsuranceAncillary1: this.initFilter1(),
        filterInsuranceAncillary2: this.initFilter2(),
      },
      filterName1: "filterInsuranceAncillary1",
      filterName2: "filterInsuranceAncillary2",
      repository: "insurance_ancillary",
      resource: "insurance_ancillaries",
      tableRef: {
        filterInsuranceAncillary1: "InsuranceAncillaryDetailTableRef1",
        filterInsuranceAncillary2: "InsuranceAncillaryDetailTableRef2",
      },
      fields: [
        {
          key: "rowSelector",
          label: "Incassa",
          sortable: false,
          thStyle: {
            width: "20px",
          },
        },
        {
          key: "insurance_policy.insurer.title",
          label: this.getDictionary("title", "insurer"),
          sortable: false,
        },
        {
          key: "insurance_policy.number",
          label: this.getDictionary("title", "insurance_policy"),
          sortable: false,
        },
        {
          key: "broker",
          label: this.getDictionary("salesman"),
          sortable: true,
          sortKey: "byBroker.BUID",
        },
        {
          key: "effective_at",
          label: this.getDictionary("effective_at", "insurance_ancillary"),
          sortable: true,
          sortKey: "effective_at",
          formatter: (value) => toLocaleDate(value),
        },
        {
          key: "delay_until",
          formatter: (value) => toLocaleDate(value),
          sortable: true,
          label: this.getDictionary("delay_until", "insurance_ancillary"),
        },
        {
          key: "code.value",
          label: this.getDictionary("code", "insurance_ancillary"),
          sortable: false,
        },
        {
          key: "insurance_policy.insurance_risk.risk_branch.title",
          label: this.getDictionary("title", "risk_branch"),
          sortable: false,
        },
        {
          key: "insurance_policy.insurance_risk.title",
          label: this.getDictionary("insurance_risk"),
          sortable: false,
        },
        {
          key: "net",
          label: this.getDictionary("net", "insurance_ancillary"),
          sortable: false,
          class: "text-center",
          formatter: (value) => toLocaleCurrency(value),
          tdClass: "text-right",
          thClass: "text-right",
        },
        {
          key: "tax",
          label: this.getDictionary("tax", "insurance_ancillary"),
          sortable: false,
          class: "text-center",
          formatter: (value) => toLocaleCurrency(value),
          tdClass: "text-right",
          thClass: "text-right",
        },
        {
          key: "gross",
          label: this.getDictionary("gross", "insurance_ancillary"),
          sortable: false,
          class: "text-center",
          formatter: (value) => toLocaleCurrency(value),
          tdClass: "text-right",
          thClass: "text-right",
        },
        {
          key: "status_ancillary.text",
          label: this.getDictionary("status_ancillary", "insurance_ancillary"),
          sortable: false,
        },
      ],
    };
  },
  components: {
    BaseIcon,
    InsuranceAncillaries,
    ExportOptions,
  },
  methods: {
    getDictionary,
    initFilter1() {
      let init = {
        byRegistry: { id: this.resourceId },
        byAttribute: { status_ancillary: 0, code: "!X" },
      };
      return init;
    },
    initFilter2() {
      let init = {
        byRegistry: { id: this.resourceId },
        byAttribute: { status_ancillary: "!0", code: "!X" },
      };
      return init;
    },
    onSearch(name) {
      let criteria = this.filter[name];
      this.saveFilterByName({ name, criteria });
      this.$refs[this.tableRef[name]]
        .fetch()
        .then(() => {
          this.removeFilterByName(name);
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({ preset: "error", text: errMsg });
        });
    },
    onAddBookEntry(row) {
      this.$router.push({
        name: `book_entries.create`,
        params: {
          mode: "IV",
          titleId: row.id,
          insuranceRiskId: row.insurance_risk_id,
        },
      });
    },
    onReloadTables() {
      this.onSearch(this.filterName1);
      this.onSearch(this.filterName2);
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
  },
  computed: {
    agencyHasFee() {
      return this.$store.state.auth.use_fee === "Y" ? true : false;
    },
  },
  mounted() {
    this.onSearch(this.filterName1);
    this.onSearch(this.filterName2);
  },
  created() {
    if (this.agencyHasFee) {
      const feeGrossField = {
        key: "fee_gross",
        label: this.getDictionary("fee_gross", "insurance_ancillary"),
        formatter: (value) => toLocaleCurrency(value),
        sortable: true,
        thClass: "text-right",
        thStyle: {
          "text-align": "right",
        },
        tdClass: "text-right",
      };
      this.fields.splice(12, 0, feeGrossField);
    }
  },
};
</script>
