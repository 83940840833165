<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div
        v-if="!isLoading"
        style="display: flex"
        :data-imported="checkIsImported"
      >
        <!-- <div v-if="!isLoading"> -->
        <!-- v-for="(item, index) in detailFields[
            `status_registry_${status_registry_value}`
          ]" -->
        <div style="flex: 1" data-type="registry-general">
          <!-- <div> -->
          <p class="mt-2 mb-1">
            <strong>Ownership</strong>: &nbsp;<span>{{
              evaluateOwnership
            }}</span>
          </p>
          <details-card
            v-for="(item, index) in dtlFlds"
            :key="index"
            :title="index"
            :registryId="resourceId"
            :addressBookId="item.id || null"
            :header="item.header"
            :edit="item.edit || false"
            :shortcut="item.shortcut"
            :create="item.create || false"
            :fields="item.fields"
            :beForm="beForm"
            :data="details"
            :repository="item.repository"
            @update="onUpdate"
            @create="onCreate"
          >
          </details-card>
        </div>
        <template
          v-if="
            $store.state.auth.settings.attributables.LISALEX &&
            $store.state.auth.settings.attributables.LISALEX === 'Y'
          "
        >
          <div style="width: 30%; padding: 8px 0 8px 30px">
            <div style="position: sticky; top: 20px">
              <iframe
                style="border: 0; margin: 0; padding: 0; min-height: 500px"
                :src="`https://${$store.state.auth.config_agency_name}${computeLisaLexDomain}widget/${this.resourceId}?token=${$store.state.auth.token}`"
              ></iframe>
            </div>
          </div>
        </template>
        <!-- :labels="labels" -->
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import ShowMixin from "@/mixins/ShowMixin";
import FormMixin from "@/mixins/FormMixin";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import BaseIcon from "@/components/BaseIcon";
import DetailsCard from "@/components/DetailsCard";
import { mapGetters, mapActions } from "vuex";

export default {
  mixins: [FormMixin, ShowMixin, ShortcutMixin],
  name: "General",
  props: {
    resourceId: Number,
  },
  components: {
    BaseIcon,
    DetailsCard,
  },
  data() {
    return {
      repositoryResourceId: {
        correspondence: {
          id: null,
        },
        address_book: {
          id: null,
          scheda_recapito_1: {
            id: null,
          },
          scheda_recapito_2: {
            id: null,
          },
          scheda_recapito_3: {
            id: null,
          },
        },
      },
      companies: [],
      status_registry_value: 0,
      details: null,
      repository: "registry",
      resource: "registries", // REVIEW: add anywhere if required by check permissions on show
      address_book_id: null,
      correspondence_id: null,
      allRepositories: ["registry", "address_book", "correspondence"],
      mandatoryFields: {
        registry: {},
        address_book: {},
        correspondence: {
          registry_id: this.resourceId,
          status_correspondence: null,
        },
      },
      isLoading: true,
      generalLabels: null,
      defaultLabels: {
        registry: {
          "province.default": "Provincia",
          "province_ispl.default": "Provincia emissione",
          // "country.default": "Nazione",
          "country_ispl.default": "Nazione emissione",
        },
        correspondence: {
          "province.default": "Provincia",
          // "country.default": "Nazione",
        },
      },
      // dovranno diventare così
      detailFields: {
        status_registry_0: {
          identificativi: {
            header: "Dati Anagrafici",
            edit: false,
            shortcut: {
              label: "Modifica",
              click: () => {
                // IMPORTANT questa General viene aperta anche da una registry table in edit di 1 RegistryGroup
                // ma rimanda alla edit di Registry.
                // per fixare la breadcrumb, togliendo "Gruppi" e mettendo "Clienti", bisogna per forza ricreare la breadcrumb
                // TODO questo comportamento andrebbe esteso per ogni rotta trasversale ai moduli o ai tab (come in questo caso)
                // UNCOMMENT to FIX and properly set the breadcrumbs: must recreate the path
                this.emptyQueue();
                this.notifyQueue({
                  level: 0,
                  path: "",
                  text: "Portafoglio",
                });
                this.notifyQueue({
                  level: 1,
                  path: "module.PFOLIO",
                  text: "Gestione Portafoglio",
                });
                this.notifyQueue({
                  level: 2,
                  path: "module.PFOLIO",
                  text: "Clienti",
                  tab: "#Registries",
                });
                // TODO SWITCH $router push/replace when UNCOMMENT
                // this.$router.replace({
                //   name: "registries.edit",
                //   params: { id: `${this.resourceId}` },
                // });
                this.shortcut(
                  "registries.edit",
                  this.resourceId,
                  null,
                  null,
                  null,
                  {
                    /* fromShortcut: true */
                  },
                  null,
                  null,
                  this.$options.name
                );
                // this.shortcut(
                //   "registries.edit",
                //   this.resourceId,
                //   null,
                //   null,
                //   null,
                //   {
                //     /* fromShortcut: true */
                //   },
                //   [
                //     {
                //       text: "Portafoglio",
                //       path: "",
                //       tab: "",
                //       level: 0,
                //       disabled: true,
                //     },
                //     {
                //       text: "Gestione Portafoglio",
                //       path: "module.PFOLIO",
                //       tab: "",
                //       level: 1,
                //     },
                //     {
                //       text: "Clienti",
                //       path: "module.PFOLIO",
                //       tab: "#Registries",
                //       level: 2,
                //     },
                //   ],
                //   null,
                //   this.$options.name
                // );
              },
            },
            repository: "registry",
            fields: [
              {
                label: this.getDictionary("status_registry", "registry"),
                option: "status_registry",
                value: "status_registry.text",
              },
              {
                label: this.getDictionary("attribute_TITL_value", "registry"),
                value: "attributables.TITL",
              },
              {
                label: this.getDictionary("attribute_NAME_value", "registry"),
                value: "attributables.NAME",
              },
              {
                label: this.getDictionary("attribute_SURN_value", "registry"),
                value: "attributables.SURN",
              },
              {
                label: this.getDictionary("attribute_SEX_value", "registry"),
                value: "attributables.SEX",
              },
              {
                label: this.getDictionary("attribute_NINO_value", "registry"),
                value: "attributables.NINO",
              },
              {
                label: this.getDictionary("attribute_MARS_value", "registry"),
                value: "attributables.MARS",
              },
              {
                label: this.getDictionary("attribute_PROF_value", "registry"),
                value: "attributables.PROF",
              },
              {
                label: this.getDictionary("attribute_CHAN_value", "registry"),
                value: "attributables.CHAN",
              },
            ],
          },
          residenza: {
            header: "Residenza / Sede Legale",
            repository: "correspondence",
            edit: false,
            fields: [
              {
                label: this.getDictionary(
                  "attribute_ADDR_value",
                  "correspondence"
                ),
                value: "attributables.ADDR",
                relation: "correspondences",
                status: 0, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary(
                  "attribute_CITY_value",
                  "correspondence"
                ),
                value: "attributables.CITY",
                relation: "correspondences",
                status: 0, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary(
                  "attribute_CITY2_value",
                  "correspondence"
                ),
                value: "attributables.CITY2",
                relation: "correspondences",
                status: 0, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary("province"),
                value: "attributables.CITY",
                relation: "correspondences",
                virtual: "PROVINCE",
                status: 0, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary(
                  "attribute_ZIPC_value",
                  "correspondence"
                ),
                value: "attributables.ZIPC",
                relation: "correspondences",
                status: 0, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary(
                  "attribute_NOR_value",
                  "correspondence"
                ),
                value: "attributables.CITY",
                virtual: "NATION",
                relation: "correspondences",
                status: 0,
              },
              {
                label: `${this.getDictionary(
                  "attribute_NOR_value",
                  "correspondence"
                )} estera`,
                value: "attributables.NOR",
                relation: "correspondences",
                status: 0, // Residenza / Sede Legale
              },
            ],
          },
          corrispondenza: {
            header: "Indirizzo di Corrispondenza",
            repository: "correspondence",
            edit: false,
            fields: [
              {
                label: this.getDictionary(
                  "attribute_NAME2_value",
                  "correspondence"
                ),
                value: "attributables.NAME2",
                relation: "correspondences",
                status: 1, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary(
                  "attribute_ADDR_value",
                  "correspondence"
                ),
                value: "attributables.ADDR",
                relation: "correspondences",
                status: 1, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary(
                  "attribute_CITY_value",
                  "correspondence"
                ),
                value: "attributables.CITY",
                relation: "correspondences",
                status: 1, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary(
                  "attribute_CITY2_value",
                  "correspondence"
                ),
                value: "attributables.CITY2",
                relation: "correspondences",
                status: 1, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary("province"),
                value: "attributables.CITY",
                relation: "correspondences",
                virtual: "PROVINCE",
                status: 1, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary(
                  "attribute_ZIPC_value",
                  "correspondence"
                ),
                value: "attributables.ZIPC",
                relation: "correspondences",
                status: 1, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary(
                  "attribute_NOR_value",
                  "correspondence"
                ),
                value: "attributables.CITY",
                virtual: "NATION",
                relation: "correspondences",
                status: 1,
              },
              {
                label: `${this.getDictionary(
                  "attribute_NOR_value",
                  "correspondence"
                )} estera`,
                value: "attributables.NOR",
                relation: "correspondences",
                status: 1, // Residenza / Sede Legale
              },
            ],
          },
          nascita: {
            header: "Nato a:",
            repository: "registry",
            edit: false,
            shortcut: {
              label: "Modifica",
              click: () => {
                this.shortcut(
                  "registries.edit",
                  this.resourceId,
                  null,
                  null,
                  null,
                  {
                    /* fromShortcut: true */
                  },
                  [
                    {
                      text: "Portafoglio",
                      path: "",
                      tab: "",
                      level: 0,
                      disabled: true,
                    },
                    {
                      text: "Gestione Portafoglio",
                      path: "module.PFOLIO",
                      tab: "",
                      level: 1,
                    },
                    {
                      text: "Clienti",
                      path: "module.PFOLIO",
                      tab: "#Registries",
                      level: 2,
                    },
                  ],
                  null,
                  this.$options.name
                );
              },
            },
            fields: [
              {
                label: this.getDictionary("attribute_POB_value", "registry"),
                value: "attributables.POB",
              },
              {
                label: this.getDictionary("province"),
                value: "attributables.POB",
                virtual: "PROVINCE",
              },
              // {
              //   label: "attribute_NOB_value",
              //   value: "attributables.NOB",
              // },
              {
                label: this.getDictionary("attribute_NOB_value", "registry"),
                value: "attributables.POB",
                virtual: "NATION",
              },
              {
                label: this.getDictionary("attribute_DOB_value", "registry"),
                value: "attributables.DOB",
              },
              // {
              //   label: this.getDictionary("attribute_NOB2_value", "registry"),
              //   value: "attributables.NOB2",
              // },
              {
                label: this.getDictionary("attribute_NOB2_value", "registry"),
                value: "attributables.NOB",
                virtual: "NATION2",
              },
              {
                label: this.getDictionary("attribute_POB2_value", "registry"),
                value: "attributables.POB2",
              },
            ],
          },
          document: {
            header: "Estremi del documento registrato",
            edit: false,
            shortcut: {
              label: "Modifica",
              click: () => {
                this.shortcut(
                  "registries.edit",
                  this.resourceId,
                  null,
                  null,
                  null,
                  {
                    /* fromShortcut: true */
                  },
                  [
                    {
                      text: "Portafoglio",
                      path: "",
                      tab: "",
                      level: 0,
                      disabled: true,
                    },
                    {
                      text: "Gestione Portafoglio",
                      path: "module.PFOLIO",
                      tab: "",
                      level: 1,
                    },
                    {
                      text: "Clienti",
                      path: "module.PFOLIO",
                      tab: "#Registries",
                      level: 2,
                    },
                  ],
                  null,
                  this.$options.name
                );
              },
            },
            repository: "registry",
            fields: [
              {
                label: this.getDictionary("attribute_DOCN_value", "registry"),
                value: "attributables.DOCN",
              },
              {
                label: this.getDictionary("attribute_DOCT_value", "registry"),
                value: "attributables.DOCT",
              },
              {
                label: this.getDictionary("attribute_DOCX_value", "registry"),
                value: "attributables.DOCX",
              },
              {
                label: this.getDictionary("attribute_DOCS_value", "registry"),
                value: "attributables.DOCS",
              },
              {
                label: this.getDictionary("attribute_ISPL_value", "registry"),
                value: "attributables.ISPL",
              },
              {
                label: this.getDictionary("province_ispl"),
                value: "attributables.ISPL",
                virtual: "PROVINCE",
              },
              {
                label: this.getDictionary("country_ispl"),
                value: "attributables.ISPL",
                virtual: "NATION",
              },
            ],
          },
        },
        status_registry_1: {
          identificativi: {
            header: "Dati Identificativi",
            // edit: this.canVerb("registries", "update"),
            edit: false,
            shortcut: {
              label: "Modifica",
              click: () => {
                // IMPORTANT questa General viene aperta anche da una registry table in edit di 1 RegistryGroup
                // ma rimanda alla edit di Registry.
                // per fixare la breadcrumb, togliendo "Gruppi" e mettendo "Clienti", bisogna per forza ricreare la breadcrumb
                // TODO questo comportamento andrebbe esteso per ogni rotta trasversale ai moduli o ai tab (come in questo caso)
                // UNCOMMENT to FIX and properly set the breadcrumbs: must recreate the path
                this.emptyQueue();
                this.notifyQueue({
                  level: 0,
                  path: "",
                  text: "Portafoglio",
                });
                this.notifyQueue({
                  level: 1,
                  path: "module.PFOLIO",
                  text: "Gestione Portafoglio",
                });
                this.notifyQueue({
                  level: 2,
                  path: "module.PFOLIO",
                  text: "Clienti",
                  tab: "#Registries",
                });
                // TODO SWITCH $router push/replace when UNCOMMENT
                // this.$router.replace({
                //   name: "registries.edit",
                //   params: { id: `${this.resourceId}` },
                // });
                this.shortcut(
                  "registries.edit",
                  this.resourceId,
                  null,
                  null,
                  null,
                  {
                    /* fromShortcut: true */
                  },
                  null,
                  null,
                  this.$options.name
                );
                // this.shortcut(
                //   "registries.edit",
                //   this.resourceId,
                //   null,
                //   null,
                //   null,
                //   {
                //     /* fromShortcut: true */
                //   },
                //   [
                //     {
                //       text: "Portafoglio",
                //       path: "",
                //       tab: "",
                //       level: 0,
                //       disabled: true,
                //     },
                //     {
                //       text: "Gestione Portafoglio",
                //       path: "module.PFOLIO",
                //       tab: "",
                //       level: 1,
                //     },
                //     {
                //       text: "Clienti",
                //       path: "module.PFOLIO",
                //       tab: "#Registries",
                //       level: 2,
                //     },
                //   ],
                //   null,
                //   this.$options.name
                // );
              },
            },
            repository: "registry",
            fields: [
              {
                label: this.getDictionary("attribute_CNAM_value", "registry"),
                value: "attributables.CNAM",
              },
              {
                label: this.getDictionary("attribute_CREG_value", "registry"),
                value: "attributables.CREG",
              },
              {
                label: this.getDictionary("attribute_NINO_value", "registry"),
                value: "attributables.NINO",
              },
              {
                label: this.getDictionary("attribute_LIAB_value", "registry"),
                value: "attributables.LIAB",
              },
              {
                label: this.getDictionary("attribute_CHAN_value", "registry"),
                value: "attributables.CHAN",
              },
              {
                label: this.getDictionary("attribute_PROF_value", "registry"),
                value: "attributables.PROF",
              },
            ],
          },
          residenza: {
            header: "Residenza / Sede Legale",
            repository: "correspondence",
            edit: false,
            fields: [
              {
                label: this.getDictionary(
                  "attribute_ADDR_value",
                  "correspondence"
                ),
                value: "attributables.ADDR",
                relation: "correspondences",
                status: 0, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary(
                  "attribute_CITY_value",
                  "correspondence"
                ),
                value: "attributables.CITY",
                relation: "correspondences",
                status: 0, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary(
                  "attribute_CITY2_value",
                  "correspondence"
                ),
                value: "attributables.CITY2",
                relation: "correspondences",
                status: 0, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary("province"),
                value: "attributables.CITY",
                relation: "correspondences",
                virtual: "PROVINCE",
                status: 0, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary(
                  "attribute_ZIPC_value",
                  "correspondence"
                ),
                value: "attributables.ZIPC",
                relation: "correspondences",
                status: 0, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary(
                  "attribute_NOR_value",
                  "correspondence"
                ),
                value: "attributables.CITY",
                virtual: "NATION",
                relation: "correspondences",
                status: 0,
              },
              {
                label: `${this.getDictionary(
                  "attribute_NOR_value",
                  "correspondence"
                )} estera`,
                value: "attributables.NOR",
                relation: "correspondences",
                status: 0, // Residenza / Sede Legale
              },
            ],
          },
          corrispondenza: {
            header: "Indirizzo di Corrispondenza",
            repository: "correspondence",
            edit: false,
            fields: [
              {
                label: this.getDictionary(
                  "attribute_NAME2_value",
                  "correspondence"
                ),
                value: "attributables.NAME2",
                relation: "correspondences",
                status: 1, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary(
                  "attribute_ADDR_value",
                  "correspondence"
                ),
                value: "attributables.ADDR",
                relation: "correspondences",
                status: 1, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary(
                  "attribute_CITY_value",
                  "correspondence"
                ),
                value: "attributables.CITY",
                relation: "correspondences",
                status: 1, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary(
                  "attribute_CITY2_value",
                  "correspondence"
                ),
                value: "attributables.CITY2",
                relation: "correspondences",
                status: 1, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary("province"),
                value: "attributables.CITY",
                relation: "correspondences",
                virtual: "PROVINCE",
                status: 1, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary(
                  "attribute_ZIPC_value",
                  "correspondence"
                ),
                value: "attributables.ZIPC",
                relation: "correspondences",
                status: 1, // Residenza / Sede Legale
              },
              {
                label: this.getDictionary(
                  "attribute_NOR_value",
                  "correspondence"
                ),
                value: "attributables.CITY",
                virtual: "NATION",
                relation: "correspondences",
                status: 1,
              },
              {
                label: `${this.getDictionary(
                  "attribute_NOR_value",
                  "correspondence"
                )} estera`,
                value: "attributables.NOR",
                relation: "correspondences",
                status: 1, // Residenza / Sede Legale
              },
            ],
          },
        },
        // campi comuni

        scheda_recapito_1: {
          header: "Recapiti Primari",
          edit: this.canVerb("address_books", "update"),
          repository: "address_book",
          fields: [
            {
              label: this.getDictionary("attribute_CELL_value", "address_book"),
              value: "attributables.CELL",
              relation: "address_books",
              type: "tel",
              status: 0,
            },
            {
              label: this.getDictionary("attribute_PHON_value", "address_book"),
              value: "attributables.PHON",
              relation: "address_books",
              type: "tel",
              status: 0,
            },
            {
              label: this.getDictionary("attribute_FAX_value", "address_book"),
              value: "attributables.FAX",
              relation: "address_books",
              type: "tel",
              status: 0,
            },
            {
              label: this.getDictionary("attribute_MAIL_value", "address_book"),
              value: "attributables.MAIL",
              relation: "address_books",
              rules: { email: true },
              status: 0,
            },
            {
              label: this.getDictionary("attribute_PEC_value", "address_book"),
              value: "attributables.PEC",
              relation: "address_books",
              rules: { email: true },
              status: 0,
            },
            {
              label: this.getDictionary("attribute_WEB_value", "address_book"),
              value: "attributables.WEB",
              relation: "address_books",
              status: 0,
            },
          ],
        },
        scheda_recapito_2: {
          header: "Recapiti Secondari",
          edit: this.canVerb("address_books", "update"),
          repository: "address_book",
          fields: [
            {
              label: this.getDictionary("attribute_CELL_value", "address_book"),
              value: "attributables.CELL",
              relation: "address_books",
              type: "tel",
              status: 1,
            },
            {
              label: this.getDictionary("attribute_PHON_value", "address_book"),
              value: "attributables.PHON",
              relation: "address_books",
              type: "tel",
              status: 1,
            },
            {
              label: this.getDictionary("attribute_FAX_value", "address_book"),
              value: "attributables.FAX",
              relation: "address_books",
              type: "tel",
              status: 1,
            },
            {
              label: this.getDictionary("attribute_MAIL_value", "address_book"),
              value: "attributables.MAIL",
              relation: "address_books",
              rules: { email: true },
              status: 1,
            },
            {
              label: this.getDictionary("attribute_PEC_value", "address_book"),
              value: "attributables.PEC",
              relation: "address_books",
              rules: { email: true },
              status: 1,
            },
            {
              label: this.getDictionary("attribute_WEB_value", "address_book"),
              value: "attributables.WEB",
              relation: "address_books",
              status: 1,
            },
          ],
        },
        scheda_recapito_3: {
          header: "Recapiti Alternativi",
          edit: this.canVerb("address_books", "update"),
          repository: "address_book",
          fields: [
            {
              label: this.getDictionary("attribute_CELL_value", "address_book"),
              value: "attributables.CELL",
              relation: "address_books",
              type: "tel",
              status: 2,
            },
            {
              label: this.getDictionary("attribute_PHON_value", "address_book"),
              value: "attributables.PHON",
              relation: "address_books",
              type: "tel",
              status: 2,
            },
            {
              label: this.getDictionary("attribute_FAX_value", "address_book"),
              value: "attributables.FAX",
              relation: "address_books",
              type: "tel",
              status: 2,
            },
            {
              label: this.getDictionary("attribute_MAIL_value", "address_book"),
              value: "attributables.MAIL",
              relation: "address_books",
              rules: { email: true },
              status: 2,
            },
            {
              label: this.getDictionary("attribute_PEC_value", "address_book"),
              value: "attributables.PEC",
              relation: "address_books",
              rules: { email: true },
              status: 2,
            },
            {
              label: this.getDictionary("attribute_WEB_value", "address_book"),
              value: "attributables.WEB",
              relation: "address_books",
              status: 2,
            },
          ],
        },
      },
    };
  },
  created() {
    this.companies = this.getInsurers().filter(
      (company) => company.importer_id != null
    );
  },
  methods: {
    fetchForm() {
      let queryString = `relations/byAddressBook/byCorrespondence`;
      let promises = [];
      return this.fetchShowForm(this.repository, this.resourceId, queryString)
        .then((response) => {
          const data = response.data.data;
          if (data === false) return;
          let found;
          this.details = data;
          this.status_registry_value = data.status_registry.value;
          // NOTE: have to move "custom" property from root of detailFields (added before by ShowMixin) to sub-properties
          // detailFields.status_registry_0 and detailFields.status_registry_1.
          // NOTA: altrimenti con questo detailFields non-standard i "custom" fields non verrebbero inclusi nel DetailsCard
          // (vedi come è fatto il v-for nel template qui sopra...)
          // move "custom" fields to the right place
          // if(Object.prototype.hasOwnProperty.call(this.detailFields, 'custom')) {
          //   this.detailFields["status_registry_0"]["custom"] = this.detailFields["custom"];
          //   this.detailFields["status_registry_1"]["custom"] = this.detailFields["custom"];
          // }
          //setAddressBooks
          this.setAddressBooks(data.address_books);

          const rg = this.fetchEditForm(this.repository, this.resourceId);
          promises.push(rg);

          if (data.correspondences && data.correspondences.length) {
            found = data.correspondences.find(
              (e) => e.status_correspondence.value === 0
            );
            if (found) {
              this.correspondence_id = found.id;
              promises.push(
                this.fetchEditForm(this.cor, this.correspondence_id)
              );
            }
          }
          if (data.address_books && data.address_books.length) {
            found = data.address_books.find(
              (e) => e.status_addressbook.value === 0
            );
            if (found) {
              this.address_book_id = found.id;
              this.repositoryResourceId[this.addrBook].id =
                this.address_book_id;
              // NOTA chiama sempre la edit del primario, soltanto per avere le label;
              // poi i value li risolve usando la prop "data" che contiene tutto il reponse
              promises.push(
                this.fetchEditForm(this.addrBook, this.address_book_id)
              );
            } else {
              promises.push(this.fetchCreateForm(this.addrBook));
            }
          } else {
            // nessun address book (potrebbe succedere?) chiamo la create
            promises.push(this.fetchCreateForm(this.addrBook));
          }
          Promise.all(promises)
            .then(() => {
              this.initDetailCardFormData();
              this.isLoading = false;
            })
            .catch((error) => {
              this.onFailure(error);
            });
        })
        .catch((error) => {
          this.onFailure(error);
        });
    },
    setAddressBooks(address_books) {
      // NEW
      let found, id1, id2, id3;
      found = address_books.find(
        (address_book) => address_book.status_addressbook.value === 0
      );
      if (found) id1 = found.id;

      found = address_books.find(
        (address_book) => address_book.status_addressbook.value === 1
      );
      if (found) id2 = found.id;

      found = address_books.find(
        (address_book) => address_book.status_addressbook.value === 2
      );
      if (found) id3 = found.id;

      // this.repositoryResourceId[this.addrBook]["scheda_recapito_1"].id = id1;
      // this.repositoryResourceId[this.addrBook]["scheda_recapito_2"].id = id2;
      // this.repositoryResourceId[this.addrBook]["scheda_recapito_3"].id = id3;

      // this.$set(this.detailFields["scheda_recapito_1"], "id", id1);
      if (!id1) {
        this.$set(
          this.detailFields["scheda_recapito_1"],
          `create`,
          this.canVerb("address_books", "store")
        );
        this.$set(this.detailFields["scheda_recapito_1"], `edit`, false);
      } else {
        this.$set(this.detailFields["scheda_recapito_1"], "id", id1);
      }

      if (!id2) {
        this.$set(
          this.detailFields["scheda_recapito_2"],
          `create`,
          this.canVerb("address_books", "store")
        );
        this.$set(this.detailFields["scheda_recapito_2"], `edit`, false);
      } else {
        this.$set(this.detailFields["scheda_recapito_2"], "id", id2);
      }

      if (!id3) {
        this.$set(
          this.detailFields["scheda_recapito_3"],
          `create`,
          this.canVerb("address_books", "store")
        );
        this.$set(this.detailFields["scheda_recapito_3"], `edit`, false);
      } else {
        this.$set(this.detailFields["scheda_recapito_3"], "id", id3);
      }
    },
    onFailure(error) {
      let errMsg = this.$getErrorMessage(error);
      this.$showSnackbar({
        preset: "error",
        text: `${errMsg}`,
      });
      this.isLoading = false;
    },
    onUpdate(form, repository, title, addressBookId) {
      // NOTA: per address book, non sapendo se sono primari, secondari o alternativi,
      // devo fare una ulteriore fetchEditForm passando addressBookId onde poter ricevere i dati obbligatori esatti
      // poichè nella precedente fetchForm ha caricato quelli del id primario soltanto!!!
      this.isLoading = true;
      console.log("onUpdate:", form);

      switch (repository) {
        case "registry":
          this.update(form, repository, title, addressBookId).then(() => {
            this.$emit("fetch");
            this.fetchForm().then(() => {
              this.isLoading = false;
            });
          });
          break;
        case "address_book":
          // devo richiamare il BE per aggiornare i valori dei mandatoryFields
          this.fetchEditForm(this.addrBook, addressBookId)
            .then(() => {
              form["registry"] = {
                [this.details.id]: {},
              };
              this.update(form, repository, title, addressBookId).then(() => {
                this.$emit("fetch");
                this.fetchForm().then(() => {
                  this.isLoading = false;
                });
              });
            })
            .catch((error) => {
              this.onFailure(error);
            });
          break;
        default:
          break;
      }
    },
    onCreate(payload, repository, title) {
      this.isLoading = true;
      const Repo = RepositoryFactory.get(repository);
      Repo.store(payload).then((response) => {
        let id = response.data.data.id;
        this.fetchForm().then(() => {
          this.$set(this.detailFields[`${title}`], `id`, id);
          this.$set(this.detailFields[`${title}`], `create`, false);
          this.$set(this.detailFields[`${title}`], `edit`, true);
          this.$emit("fetch");
          this.isLoading = false;
        });
      });
    },
    update(form, repository, title, addressBookId) {
      const Repo = RepositoryFactory.get(repository);
      let payload = {};
      payload = { ...this.mandatoryFields[repository] };

      // for (const key in payload) {
      //   if (
      //     payload[key] === null ||
      //     payload[key] === undefined ||
      //     payload[key] === ""
      //   ) {
      //     // console.debug("key", key)
      //     // console.debug("this.beForm[repository]",this.beForm[repository])
      //     // console.debug("this.beForm[repository][key]",this.beForm[repository][key])
      //     if (
      //       this.beForm[repository][key] &&
      //       ![null, undefined].includes(this.beForm[repository][key].value)
      //     ) {
      //       payload[key] = this.beForm[repository][key].value;
      //     }
      //   }
      //   // if (this.beForm[repository][key].type === "select") {
      //   //   payload[key] = this.beForm[repository][key].value;
      //   // }
      // }

      this.setMandatoryValues(repository, payload);

      payload = { ...payload, ...form };
      let resId =
        repository === this.repository
          ? this.resourceId
          : this.repositoryResourceId[repository].id;
      if (repository === "address_book") {
        resId = addressBookId;
      }
      console.log(resId);
      console.log(payload);
      return Repo.update(resId, payload);
      // Repo.update(resId, payload).then(() => {
      //   this.$emit("fetch");
      //   this.fetchForm().then(() => {
      //     this.isLoading = false;
      //   });
      // });
    },
    ...mapActions("queue", ["notifyQueue", "emptyQueue"]),
    ...mapGetters("auth", ["isLogged", "user", "settings"]),
    ...mapGetters("auth", {
      getInsurers: "insurers",
    }),
  },
  computed: {
    checkIsImported() {
      if (
        this?.details &&
        Object.prototype.hasOwnProperty.call(this.details, "insurer_id")
      ) {
        if (!this.details.insurer_id) return false;
        return true;
      }
      return false;
    },
    evaluateOwnership() {
      if (
        this?.details &&
        Object.prototype.hasOwnProperty.call(this.details, "insurer_id")
      ) {
        if (!this.details.insurer_id) return "Lisaweb";
        return this.companies.find((el) => el.value === this.details.insurer_id)
          .text;
      }
      return "-";
    },
    computeLisaLexDomain() {
      return process.env.VUE_APP_LISALEX;
    },
    cor() {
      return "correspondence";
    },
    addrBook() {
      return "address_book";
    },
    dtlFlds() {
      // mergia i campi di status 0 o 1 con quelli in comune
      let { status_registry_0, status_registry_1, ...rest } = this.detailFields;
      let c = this.status_registry_value
        ? status_registry_1
        : status_registry_0;
      let r = { ...c, ...rest };
      return r;
    },
  },
  mounted() {
    this.isLoading = true;
    this.fetchForm().then(() => {});
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
[data-imported="true"] {
  [data-type="registry-general"] {
    [data-title="identificativi"],
    [data-title="nascita"],
    [data-title="document"] {
      .card-header {
        .btn {
          display: none !important;
          opacity: 0 !important;
          pointer-events: none !important;
          touch-action: none !important;
          cursor: default !important;
          user-select: none !important;
          -webkit-tap-highlight-color: transparent;
        }
      }
    }
  }
}
</style>
