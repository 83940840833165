<template>
  <div class="mt-2" :busy="isLoading">
    <!-- <div :style="[!noInnerWidth ? { width: width } : {}]"> -->
    <div :style="{ width: !noInnerWidth ? width : 'auto' }">
      <div
        :style="{
          'overflow-y': !(items.length > (communication_types.length || 5))
            ? 'visible'
            : 'auto',
          'margin-bottom': saveRelationBtn ? '1rem' : '',
          'max-height': saveRelationBtn ? '500px !important' : '',
        }"
      >
        <p class="font-weight-bold" v-if="!isLoading && getGroupId()">
          {{ coord }}
        </p>
        <b-table
          :busy="isLoading"
          head-variant="light"
          :no-local-sorting="true"
          :items="items"
          :fields="getAllFields"
          :current-page="currentPage"
          per-page="0"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :sticky-header="height"
          no-border-collapse
          responsive
          show-empty
          small
          @sort-changed="sortingChanged"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <base-icon name="loading" width="35" height="35" />
              <p>Operazione in corso...</p>
            </div>
          </template>
          <template #empty>
            <template v-if="newRegistryBtn">
              <div
                class="d-flex flex-column justify-content-center align-items-center my-3"
                style="max-width: max-content; margin-inline: auto"
              >
                <p class="mb-2">
                  Effettua una ricerca oppure, se non trovi risultati, crea un
                  nuovo Cliente cliccando sul pulsante qui sotto
                </p>
                <b-button
                  class="btn-create"
                  type="button"
                  variant="primary"
                  size="sm"
                  @click="onAddNewRegistry"
                  title="Crea"
                  :disabled="!canVerb(resource, 'store')"
                  style="max-width: max-content"
                  ><b-icon icon="plus-circle" aria-hidden="true"></b-icon>
                  Crea</b-button
                >
              </div>
            </template>
            <template v-else>
              <div class="text-center my-2">
                Non ci sono informazioni da mostrare
              </div></template
            >
          </template>
          <!-- <template #cell()="data">
            {{ data.unformatted }}
          </template> -->
          <template
            v-slot:head(check)="data"
            v-if="hasChecks && !isCheckExclusive && items.length"
          >
            <base-checkbox
              v-model="checkAll"
              name="all"
              class="check"
              groupLabel=""
              :label="data.label"
              @change="
                $emit('interaction');
                checkedAll();
              "
            >
            </base-checkbox>
          </template>
          <template #cell(check)="row" v-if="hasChecks">
            <template v-if="!isCoordinatorCurrentGroup(row.item)">
              <span style="display: none !important" v-if="checkIsChecked">{{
                checkIsChecked(row.item)
              }}</span>
              <base-checkbox
                name="check"
                class="check"
                v-model="row.item.check"
                @change="checkedEl($event, row.index, row.item)"
                @input="
                  $emit('interaction');
                  onSelectRow($event, row.index, row.item);
                "
              />
            </template>
          </template>
          <template #cell(radio_check)="row" v-if="hasChecks">
            <base-checkbox
              name="check"
              class="check"
              v-model="row.item.check"
              @input="
                $emit('interaction');
                onRadioSelectRow($event, row.index, row.item);
              "
            />
          </template>
          <template #cell(customer)="{ item }" v-if="items.length">
            <span
              v-if="
                !Array.isArray(item.attributables) && !item.attributables.length
              "
              ><span v-html="formatCustomerName(item)"></span
            ></span>
          </template>
          <template #cell(localita)="{ item }" v-if="items.length">
            <div v-if="item['correspondences']">
              {{
                `${toRelationString(
                  "correspondences",
                  item.correspondences,
                  ["CITY"],
                  [0]
                )} (${toRelationString(
                  "correspondences",
                  item.correspondences,
                  ["VIRTUAL_CITY_PROVINCE"],
                  [0]
                )})`
              }}
            </div>
          </template>
          <template #cell(code)="{ item }" v-if="items.length">
            {{
              item.registry_groups
                ? item.registry_groups.map((e) => e.code).join(", ")
                : ""
            }}
          </template>
          <template #cell(status_registry)="{ item }" v-if="items.length">
            {{ item.status_registry.value === 1 ? "G" : "F" }}
          </template>
          <template #cell(insurance_policy)="{ item }" v-if="items.length">
            <span :class="{ 'text-red': !item.insurance_policies.length }">
              {{
                item.insurance_policies.filter((insurance_policy) =>
                  [0, 1, 2].includes(insurance_policy.status_policy.value)
                ).length
                  ? item.insurance_policies.filter((insurance_policy) =>
                      [0, 1, 2].includes(insurance_policy.status_policy.value)
                    ).length
                  : "no pol"
              }}
            </span>
          </template>
          <template #cell(note_counter)="row" v-if="items.length">
            <div
              role="button"
              class="info-box float-left"
              @click="customOpenNoteModal(row.item, row.index, $event.target)"
              v-b-tooltip="
                toTooltipTask(
                  row.item.tasks.filter((task) => task.task_type.value === 2),
                  'Note',
                  [
                    { label: 'Titolo', key: 'title' },
                    { label: 'Descrizione', key: 'description' },
                    {
                      label: 'Ultimo aggiornamento',
                      key: 'last_update',
                      formatter: (val) => {
                        return moment(val).format('DD/MM/YYYY HH:mm:ss');
                      },
                    },
                    {
                      label: 'Creato da',
                      key: 'owner_id',
                      formatter: (val) => retriveOwner(val),
                    },
                    {
                      label: 'Data creazione',
                      key: 'created_at',
                      formatter: (val) => {
                        return moment(val).format('DD/MM/YYYY HH:mm:ss');
                      },
                    },
                  ]
                )
              "
            >
              {{
                row.item.tasks.filter((task) => task.task_type.value === 2)
                  .length
              }}
            </div>
          </template>
          <template #cell(todo_counter)="row" v-if="items.length">
            <div
              role="button"
              class="info-box float-left"
              @click="customOpenTodoModal(row.item, row.index, $event.target)"
              v-b-tooltip="
                toTooltipTask(
                  row.item.tasks.filter((task) => task.task_type.value === 1),
                  'Attività',
                  [
                    { label: 'Titolo', key: 'title' },
                    { label: 'Descrizione', key: 'description' },
                    {
                      label: 'Ultimo aggiornamento',
                      key: 'last_update',
                      formatter: (val) => {
                        return moment(val).format('DD/MM/YYYY HH:mm:ss');
                      },
                    },
                  ]
                )
              "
            >
              {{
                row.item.tasks.filter((task) => task.task_type.value === 1)
                  .length
              }}
            </div>
          </template>
          <template #cell(appointment_counter)="row" v-if="items.length">
            <div
              role="button"
              class="info-box float-left"
              @click="
                customOpenAppointmentModal(row.item, row.index, $event.target)
              "
              v-b-tooltip="
                toTooltipTask(
                  row.item.tasks.filter((task) => task.task_type.value === 0),
                  'Appuntamenti',
                  [
                    { label: 'Titolo', key: 'title' },
                    { label: 'Descrizione', key: 'description' },
                    {
                      label: 'Data e ora inizio',
                      key: 'started_at',
                      formatter: (val) => {
                        return moment(val).format('DD/MM/YYYY HH:mm:ss');
                      },
                    },
                    {
                      label: 'Data e ora fine',
                      key: 'ended_at',
                      formatter: (val) => {
                        return moment(val).format('DD/MM/YYYY HH:mm:ss');
                      },
                    },
                    {
                      label: 'Ultimo aggiornamento',
                      key: 'last_update',
                      formatter: (val) => {
                        return moment(val).format('DD/MM/YYYY HH:mm:ss');
                      },
                    },
                  ]
                )
              "
            >
              {{
                row.item.tasks.filter((task) => task.task_type.value === 0)
                  .length
              }}
            </div>
          </template>
          <template #cell(broker)="{ item }" v-if="items.length">
            {{
              item.insurance_policies
                ? item.insurance_policies
                    .filter((e) => {
                      return e.broker && e.broker.status_broker.value === 0;
                    })
                    // filtrare solo quelle che si trovano in stati particolari:
                    // 0: non perfezionata,
                    // 1: perfezionata
                    // 2:sospesa
                    .filter((e) => {
                      return (
                        e.status_policy &&
                        [0, 1, 2].includes(e.status_policy.value)
                      );
                    })
                    .map((e) => e.broker.attributables.BUID)
                    .filter((value, index, e) => e.indexOf(value) === index)
                    .join(", ")
                : ""
            }}
          </template>
          <template #cell(document_counter)="row" v-if="items.length">
            <div
              role="button"
              class="info-box float-left"
              @click="customOpenDocModal(row.item, row.index, $event.target)"
              v-b-tooltip="
                toTooltipTask(row.item.documents, 'Documenti', [
                  { label: 'Titolo', key: 'title' },
                  { label: 'Descrizione', key: 'note' },
                  {
                    label: 'Ultimo aggiornamento',
                    key: 'last_update',
                    formatter: (val) => {
                      return moment(val).format('DD/MM/YYYY HH:mm:ss');
                    },
                  },
                ])
              "
            >
              {{ row.item.documents.length }}
            </div>
          </template>
          <template #cell(primary_group)="{ item }" v-if="items.length">
            {{
              item.registry_groups
                  ? item.registry_groups.length && item.registry_groups.find(
                      registry_group => registry_group.pivot.primary.value === 'Y'
                  ) ? item.registry_groups.find(
                      registry_group => registry_group.pivot.primary.value === 'Y'
                  ).code : ""
                  : "",
            }}
            <b-icon
              icon="award"
              font-scale="1.5"
              title="Capogruppo"
              v-if="isCoordinator(item)"
            ></b-icon>
          </template>
          <template #cell(is_coordinator)="{ item }" v-if="items.length">
            <base-checkbox
              name="is_coordinator"
              class="check"
              v-model="
                item.registry_groups[
                  getRegistryGroupIndex(item.registry_groups)
                ].pivot.is_coordinator.value
              "
              chk_val="Y"
              unchk_val="N"
              @input="
                setIsCoordinator(
                  item.registry_groups[
                    getRegistryGroupIndex(item.registry_groups)
                  ].pivot
                )
              "
            />
            <!-- REVIEW coul be true|false, Y|N depending on backend inplementation -->
          </template>
          <template #cell(is_primary_group)="row">
            <b-icon
              icon="trophy"
              font-scale="1.5"
              title="Gruppo Primario"
              v-if="isPrimary(row.item)"
            ></b-icon>
            <span v-else>-</span>
          </template>
          <template #cell(communication_type)="{ item }" v-if="items.length">
            <base-select
              label=""
              name="communication_type"
              v-model="
                item.registry_groups[
                  getRegistryGroupIndex(item.registry_groups)
                ].pivot.communication_type.value
              "
              :options="communication_types"
              @select="setCommunicationType"
              @remove="$emit('interaction')"
            />
          </template>
          <template #cell(address)="{ item }">
            <span v-html="toInfoData(item, 'address', 0)"></span>
          </template>
          <template #cell(domicilio)="{ item }">
            <span
              v-html="
                toRelationString(
                  'correspondences',
                  item.correspondences,
                  ['ADDR'],
                  [1]
                )
              "
            ></span>
          </template>
          <template #cell(phon)="{ item }">
            <span
              v-html="
                toRelationString(
                  'address_books',
                  item.address_books,
                  ['PHON'],
                  [0]
                )
              "
            ></span>
          </template>
          <template #cell(mobile)="{ item }">
            <span
              v-html="
                toRelationString(
                  'address_books',
                  item.address_books,
                  ['CELL'],
                  [0]
                )
              "
            ></span>
          </template>
          <template #cell(email1)="{ item }">
            <span
              v-html="
                toRelationString(
                  'address_books',
                  item.address_books,
                  ['MAIL'],
                  [0]
                )
              "
            ></span>
          </template>
          <template #cell(email2)="{ item }">
            <span
              v-html="
                toRelationString(
                  'address_books',
                  item.address_books,
                  ['MAIL'],
                  [1]
                )
              "
            ></span>
          </template>
          <template #cell(pec)="{ item }">
            <span
              v-html="
                toRelationString(
                  'address_books',
                  item.address_books,
                  ['PEC'],
                  [0]
                )
              "
            ></span>
          </template>
          <template #cell(address_books)="{ item }">
            <span v-html="toInfoData(item, 'address_books', null)"></span>
          </template>
          <template #cell(relative_types)="{ item }" v-if="items.length">
            <base-select
              label=""
              name="relation_types"
              v-model="item['relation_type_id']"
              :options="getFilteredRelativeTypes(item)"
              @select="onSelectRelationType($event, item)"
              @remove="onRemoveRelationType($event, item)"
            />
          </template>
          <template #cell(relation)="{ item }" v-if="items.length">
            {{
              item.status_registry.value === 1
                ? item.pivot.relative_type.male
                : item.attributables.SEX === "M"
                ? item.pivot.relative_type.male
                : item.pivot.relative_type.female
            }}
          </template>
          <template #cell(actions)="row">
            <div class="action-buttons">
              <b-button
                :disabled="!actions.includes('infomodal')"
                size="sm"
                variant="lisaweb"
                @click="openModal(row.item, row.index, $event.target)"
                class="mt-1 mr-1"
                title="Modale Dettagli"
              >
                <b-icon icon="eye"></b-icon>
              </b-button>
              <b-button
                :disabled="!actions.includes('edit')"
                size="sm"
                variant="lisaweb"
                @click="onEdit(row.item.id, row.item)"
                class="mt-1 mr-1"
                title="Modifica"
              >
                <b-icon icon="pencil-square"></b-icon>
              </b-button>
              <b-button
                :disabled="
                  row.item.relative_types.length !== 0
                    ? actions.includes('details')
                    : !actions.includes('details')
                "
                size="sm"
                variant="lisaweb"
                @click="getRelativeData(row)"
                class="mt-1 mr-1"
                :title="
                  row.detailsShowing ? 'Chiudi dettagli' : 'Apri dettagli'
                "
              >
                <b-icon v-if="row.detailsShowing" icon="chevron-up"></b-icon>
                <b-icon v-else icon="chevron-down"></b-icon>
              </b-button>
              <b-button
                :disabled="!actions.includes('destroy')"
                size="sm"
                variant="lisaweb"
                @click="onDestroy(row.item.id)"
                class="mt-1 mr-1"
                title="Elimina"
              >
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </template>
          <template #cell(rowSelector)="row">
            <div class="action-buttons">
              <b-button
                size="sm"
                variant="lisaweb"
                @click="onChosen(row.item)"
                class="mt-1 mr-1"
                title="Seleziona"
              >
                Seleziona
              </b-button>
            </div>
          </template>
          <template #cell(rowSelector2)="row">
            <div class="action-buttons">
              <!-- <b-button
                size="sm"
                variant="lisaweb"
                @click="onChosen(row.item, 'rowSelector2')"
                class="mt-1"
                title="Stampa"
                >Stampa
              </b-button> -->
              <b-button
                size="sm"
                variant="lisaweb"
                class="mt-1 mr-1"
                title="Stampa"
                @click="onChosen(row.item, 'rowSelector2')"
              >
                <b-icon icon="printer"></b-icon>
              </b-button>
            </div>
          </template>
          <template #cell(rowSelector3)="row">
            <div class="action-buttons">
              <!-- <ul>
                <li v-for="(document, index) in row.item.documents" :key="index">
                  <b-link
                    href="download"
                    @click.prevent="onChosen(document, 'rowSelector3')"
                  >
                    <span v-html="document.title"></span>
                  </b-link>
                </li>
              </ul> -->
              <b-button
                size="sm"
                variant="lisaweb"
                class="mt-1 mr-1"
                title="Download"
                @click="onChosen(row.item, 'rowSelector3')"
                :disabled="!hasPrivacyDoc(row.item)"
              >
                <b-icon icon="cloud-download"></b-icon>
              </b-button>
            </div>
          </template>
          <template #row-details="row">
            <b-card>
              <b-row
                class="mb-2"
                v-for="registry in row.item.registries"
                :key="registry.id"
              >
                <b-col sm="3" class="text-sm-right">
                  <b>{{ registry.family_relation | capitalize }}:</b>
                </b-col>
                <b-col>{{ registry.name }}</b-col>
              </b-row>
            </b-card>
          </template>
        </b-table>
      </div>
      <b-row v-if="!noPagination">
        <div class="show-text">
          <p>Mostra</p>
        </div>
        <div class="group">
          <b-form-group
            label-for="per-page-select"
            label-cols-sm="3"
            label-cols-md=""
            label-cols-lg="3"
            label-size="sm"
            md="auto"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              style="width: 68px"
              size="sm"
              @change="onPageChange(1)"
              :disabled="!items.length"
            ></b-form-select>
          </b-form-group>
        </div>

        <div class="page">
          <p>di {{ totalItems }} per pagina</p>
        </div>
        <div class="pagination" v-if="totalItems">
          <b-pagination
            v-model="currentPage"
            limit="3"
            :total-rows="totalItems"
            :per-page="perPage"
            size="sm"
            @change="onPageChange"
          ></b-pagination>
        </div>
        <div class="input_pagination" v-if="totalItems && !noInputPaginator">
          <base-input-pagination
            @pagination="onInputPagination"
          ></base-input-pagination>
        </div>
      </b-row>
      <!-- <div>{{ checkedIds }}</div> -->
      <b-button
        v-if="saveRelationBtn && totalItems"
        type="button"
        @click="saveEvent()"
        variant="outline-lisaweb"
        size="sm"
        class="mt-2 float-right"
        :disabled="!selectedItem"
      >
        Salva
      </b-button>
      <b-modal
        :id="infomodalName"
        @show="onModalShow"
        @hidden="onModalHidden"
        :title="modalTitle"
        size="xl"
        ok-only
        ok-variant="lisaweb"
        header-bg-variant="lisaweb"
        no-close-on-backdrop
      >
        <div>
          <b-tabs content-class="pt-1" lazy v-model="tabIndex">
            <!-- :active="index === tabIndex" -->
            <b-tab
              v-for="(tab, index) in tabs"
              :active="index === tabIndex"
              :key="index"
              :title="tab.text"
              @click="setTab(tab, index)"
              title-item-class="lisaweb"
            >
              <button-group-tab></button-group-tab>
              <keep-alive>
                <component
                  v-if="selected === tab.name"
                  :is="selected"
                  :resourceId="modalResourceId"
                  :dbRowData="dbRowData"
                  @fetch="fetch"
                ></component>
              </keep-alive>
            </b-tab>
          </b-tabs>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import template from "./template";
import BaseIcon from "@/components/BaseIcon";
import ButtonGroupTab from "@/components/ButtonGroupTab";
import General from "@/components/wallet/registries/details/General";
import Correspondences from "@/components/wallet/registries/details/Correspondences";
import InsurancePolicy from "@/components/wallet/registries/details/InsurancePolicy";
import InsuranceAncillary from "@/components/wallet/registries/details/InsuranceAncillary";
import Takings from "@/components/wallet/registries/details/Takings";
import Claim from "@/components/wallet/registries/details/Claim";
import Appointments from "@/components/wallet/registries/details/Appointments";
import Todo from "@/components/wallet/registries/details/Todo";
import Notes from "@/components/wallet/registries/details/Notes";
import Group from "@/components/wallet/registries/details/Group";
import Documents from "@/components/wallet/registries/details/Documents";
import Relations from "@/components/wallet/registries/details/Relations";
import BaseCheckbox from "@/components/form/BaseCheckbox";
import BaseRadio from "@/components/form/BaseRadio";
import BaseSelect from "@/components/form/BaseSelect";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import { mapGetters } from "vuex";
import BaseInputPagination from "@/components/form/BaseInputPagination";

export default {
  name: "RegistriesTable",
  mixins: [StorageGetterMixin],
  extends: template,
  data() {
    return {
      // args: null,
      companies: [],
      checkedIds: [],
      relativeTypes: [],
      selectedItem: null,
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      tabs: [
        { name: "General", text: "Generale" },
        { name: "Correspondences", text: "Indirizzi" },
        { name: "InsurancePolicy", text: "Polizze" },
        { name: "InsuranceAncillary", text: "Titoli" },
        { name: "Takings", text: "Incassi" },
        { name: "Claim", text: "Sinistri" },
        { name: "Appointments", text: "Appuntamenti" },
        { name: "Notes", text: "Note" },
        { name: "Todo", text: "Attività" },
        { name: "Group", text: "Gruppi" },
        { name: "Documents", text: "Doc" },
        { name: "Relations", text: "Relazioni" },
      ],
      selected: "General", // dynamically load selected component in tabs
      dbRowData: null, // prop for the dynamic components
      modalResourceId: null, // prop for the dynamic components
      tabIndex: 0, // remember last visited tab: set active tab when modal gets opened
      // moved to registry_groups.edit:
      // communication_types: [],
      // communication_type: 4, // capogruppo
      // coordinator: {},
    };
  },
  props: {
    saveRelationBtn: {
      type: Boolean,
      default: false,
    },
    hasChecksPaginated: {
      type: Boolean,
      default: false,
    },
    newRegistryBtn: {
      type: Boolean,
      default: false,
    },
  },
  inject: {
    gId: { default: null },
    args: { default: null },
    communication_types: { default: [] },
    communication_type: { default: 4 },
    coordinator: { default: {} },
  },
  components: {
    BaseIcon,
    BaseInputPagination,
    General,
    Correspondences,
    InsurancePolicy,
    InsuranceAncillary,
    Takings,
    Claim,
    Appointments,
    // Claim: () => import("@/components/wallet/registries/details/Claim"),
    // Appointments: () =>
    //   import("@/components/wallet/registries/details/Appointments"),
    Notes,
    Group,
    Documents,
    Relations,
    ButtonGroupTab,
    BaseCheckbox,
    BaseRadio,
    BaseSelect,
    Todo,
  },
  created() {},
  beforeMount() {
    this.relativeTypes = this.getRelativeTypes();
    // let gId = this.getGroupId();
    // if (gId) {
    //   if (!this.args) {
    //     this.$set(this, "args", {});
    //   }
    //   // questo solleva un warning, ma ok così
    //   // [Vue warn]: Avoid mutating an injected value directly
    //   // since the changes will be overwritten whenever the provided component
    //   // re-renders. injection being mutated: "args"
    //   this.$set(this.args, "extra", {
    //     runBefore: () => {
    //       this.handleCoordinator(gId);
    //     },
    //   });
    // }
    // const path = "auth/";
    // const resources = [];
    // if (!this.hasLoadedResource("countries"))
    //   // this.getResources("auth/countries");
    //   // resources.push({
    //   //   path: path,
    //   //   value: "countries",
    //   // });
    //   resources.push(
    //     this.$store
    //       .dispatch(`${path}countries`)
    //       .then(() => {
    //         console.info(
    //           `background data "${path}countries" fetched correctly`
    //         );
    //       })
    //       .catch((error) => {
    //         let errMsg = this.$getErrorMessage(error);
    //         this.$showSnackbar({ preset: "error", text: errMsg });
    //       })
    //     // .finally(() => {
    //     //   this.unloadMsg();
    //     //   this.isLoading = false;
    //     // })
    //   );
    // if (!this.hasLoadedResource("councils"))
    //   // this.getResources("auth/councils");
    //   // resources.push({
    //   //   path: path,
    //   //   value: "councils",
    //   // });
    //   resources.push(
    //     this.$store
    //       .dispatch(`${path}councils`)
    //       .then(() => {
    //         console.info(`background data "${path}councils" fetched correctly`);
    //       })
    //       .catch((error) => {
    //         let errMsg = this.$getErrorMessage(error);
    //         this.$showSnackbar({ preset: "error", text: errMsg });
    //       })
    //     // .finally(() => {
    //     //   this.unloadMsg();
    //     //   this.isLoading = false;
    //     // })
    //   );
    // if (!this.hasLoadedResource("provinces"))
    //   // this.getResources("auth/provinces");
    //   // resources.push({
    //   //   path: path,
    //   //   value: "provinces",
    //   // });
    //   resources.push(
    //     this.$store
    //       .dispatch(`${path}provinces`)
    //       .then(() => {
    //         console.info(
    //           `background data "${path}provinces" fetched correctly`
    //         );
    //       })
    //       .catch((error) => {
    //         let errMsg = this.$getErrorMessage(error);
    //         this.$showSnackbar({ preset: "error", text: errMsg });
    //       })
    //     // .finally(() => {
    //     //   this.unloadMsg();
    //     //   this.isLoading = false;
    //     // })
    //   );
    // if (resources.length) {
    //   this.getResources(resources)
    //     .then(() => {
    //       console.log("All promises went through correctly");
    //       this.isLoading = false;
    //     })
    //     .catch((error) => {
    //       console.log("One or more promises crashed... :|");
    //       this.onFailure(error);
    //     })
    //     .finally(() => {
    //       // this.unloadMsg();
    //       this.isLoading = false;
    //     });
    // } else {
    this.isLoading = false;
    // }
    /* */
    /*
    this.getResources(resources):

    let promises = [];
    promises.push(`${path}countries`);
    promises.push(`${path}provinces`);
    promises.push(`${path}councils`);
    Promise.all(promises)
      .then(() => {
        this.isLoading = false;
      })
      .catch((error) => {
        this.onFailure(error);
      });
    */
    /* */
  },
  mounted() {
    this.companies = this.getInsurers();
  },
  methods: {
    formatCustomerName(item) {
      const ownership =
        Object.prototype.hasOwnProperty.call(item, "insurer") &&
        item.insurer != null &&
        Object.prototype.hasOwnProperty.call(item.insurer, "importer_id")
          ? this.formatImporterName(item.insurer.importer_id)
          : "Lisaweb";
      const nome =
        item.status_registry.value === 1
          ? item.attributables.CNAM
          : `${item.attributables.SURN} ${item.attributables.NAME}`;
      return `<span class="importer-name">${ownership}</span><br /><span>${nome}</span>`;
    },
    formatImporterName(val) {
      const name = this.companies.find((el) => el.importer_id === val).text;
      return `${name}`;
    },
    retriveOwner(value) {
      if (!value) return "-";
      return (
        this.getUsers().find((user) => user.value === value).username ?? "-"
      );
    },
    isPrimary(item) {
      let found = item.registry_groups.find((g) => g.id == this.getGroupId());
      if (found) {
        return found.pivot.primary.value === "Y";
      }
      return false;
    },
    isCoordinator(item) {
      if (!item.registry_groups || !item.registry_groups.length) return false;
      const found = item.registry_groups.find(
        (registry_group) => registry_group.pivot.is_coordinator.value === "Y"
      );
      if (!found) return false;
      return true;
    },
    isCoordinatorCurrentGroup(item) {
      if (!item.registry_groups || !item.registry_groups.length) return false;
      const current_group = item.registry_groups.find(
        (g) => g.id == this.getGroupId()
      );
      if (!current_group || current_group?.pivot?.is_coordinator?.value === "N")
        return false;
      return true;
    },
    checkIsChecked(item) {
      const evl = this.checkedIds.indexOf(item.id) >= 0;
      item.check = evl;
      return evl;
    },
    checkedEl(event, index, item) {
      console.log("clicked :", index);
      if (!this.hasChecksPaginated) return;
      if (event && this.checkedIds.indexOf(item.id) === -1) {
        this.checkedIds.push(item.id);
      } else {
        const i = this.checkedIds.indexOf(item.id);
        if (i > -1) {
          this.checkedIds.splice(i, 1);
        }
      }
      this.$emit("singleCheck", this.checkedIds);
    },
    checkedAll() {
      this.items.forEach((item) => {
        this.checkedEl(true, null, item);
      });
    },
    saveEvent() {
      // this.$parent.$emit("saveRelation", this.selectedItem);
      let vm = this.$parent;
      while (vm) {
        vm.$emit("saveRelation", this.selectedItem);
        vm = vm.$parent;
      }
    },
    onSelectRelationType(selectedOption, item) {
      this.selectedItem = item;
      item.check = true;
      this.onRadioSelectRow(selectedOption, null, item);
      this.visible = true;
    },
    onRemoveRelationType(selectedOption, item) {
      item.check = false;
      this.onRadioSelectRow(selectedOption, null, item);
      this.visible = false;
    },
    onRadioSelectRow(value, index, item) {
      // value == value of checkbox (ie. true or false, or whatever is stored in v-model)
      // index == visual index of row (i.e. the index of the row wrt the displayed rows)
      // item == the clicked row item data
      // console.log("onRadioSelectRow");
      if (item.check) {
        if (this.isCheckExclusive) {
          // empty array
          this.selectedRows = [];
          // unselect other evetually selected
          this.unselectAll();
          // reselect this one
          this.items.find((e) => e.id === item.id).check = true;
        }
        // push
        this.selectedRows.push(item.id);
      } else {
        // pop
        this.selectedItem = null;
        this.selectedRows = this.selectedRows.filter((i) => i !== item.id);
        item["relation_type_id"] = null;
      }
      // this sets the global checkbox status (remove if use buttons)
      this.checkAll = false;
    },
    onFailure(error) {
      let errMsg = this.$getErrorMessage(error);
      this.$showSnackbar({
        preset: "error",
        text: `${errMsg}`,
      });
      this.isLoading = false;
    },
    // getGroupId() {
    //   let group_id;
    //   const filter = this.$parent.$data.filter;
    //   if (filter && Object.keys(filter).length && filter.byRegistryGroup) {
    //     group_id = filter.byRegistryGroup.id;
    //   }
    //   // se group_id non è null, vedo se c'è un ! davanti, altrimenti ...
    //   return group_id
    //     ? !group_id.toString().startsWith("!")
    //       ? group_id
    //       : group_id.toString().slice(1)
    //     : group_id;
    // },
    getGroupId() {
      return this.gId;
    },
    getRegistryGroupIndex(registry_groups) {
      let group_id = this.getGroupId();
      let ret = -1;
      ret = registry_groups.findIndex((elem) => elem.id == group_id);
      // console.debug("getRegistryGroupIndex ret: ", ret);
      return ret;
    },
    // handleCoordinator(gId) {
    //   // this.isLoading = true;
    //   return this.loadCoordinator(gId);
    // },
    // loadCoordinator(gId) {
    //   const Repo = RepositoryFactory.get("registry");
    //   let queryString = `byGroupCoordinator[id]=${gId}`;
    //   return Repo.index(queryString)
    //     .then((response) => {
    //       // set the coordinator
    //       this.$set(
    //         this,
    //         "coordinator",
    //         response.data.data.length ? response.data.data[0] : {}
    //       );
    //       this.setCommunicationTypes(gId);
    //     })
    //     .catch((error) => {
    //       let errMsg = this.$getErrorMessage(error);
    //       this.$showSnackbar({ preset: "error", text: errMsg });
    //     });
    // },
    // setCommunicationTypes(gId) {
    //   const Repo = RepositoryFactory.get("registry_group");
    //   return Repo.pivot_form(gId, "registry")
    //     .then((response) => {
    //       console.log(response.data.fields.communication_type.options);
    //       // se non c'è il coordinator escludo this.communication_type (id = 4)
    //       this.communication_types =
    //         response.data.fields.communication_type.options.filter((o) =>
    //           !this.coordinator.id ? o.value !== this.communication_type : true
    //         );
    //       // this.isLoading = false;
    //     })
    //     .catch((error) => {
    //       let errMsg = this.$getErrorMessage(error);
    //       this.$showSnackbar({ preset: "error", text: errMsg });
    //     });
    // },
    setIsCoordinator(item) {
      if (item.is_coordinator.value === "Y") {
        // REVIEW coul be true|false, Y|N depending on backend inplementation
        // caller has to clear the check from all but this registry_id
        this.$emit(
          "coordinator",
          item.registry_group_id,
          item.registry_id,
          this.coordinator.id
        );
      } else {
        this.$emit(
          "interaction",
          item.registry_group_id,
          item.registry_id,
          this.coordinator.id
        );
      }
    },
    setCommunicationType(val) {
      // fa solo emit per notificare che è stato scelto qualcosa...
      this.$emit("communication", val);
    },
    async getRelativeData(row) {
      const Reporeg = RepositoryFactory.get("registry");
      let qs = "relations/byRegistry";
      let response = await Reporeg.show(row.item.id, qs);
      row.item.registries = [];
      response.data.data.registries.map((registry) => {
        const familyRelation =
          registry.pivot.relative_type[
            registry.attributables.SEX === "F" ? "female" : "male"
          ];
        let registry_id = +row.item.registries.length;

        row.item.registries.push({
          id: registry_id,
          family_relation: familyRelation,
          name: registry.formatted_title,
        });
      });
      console.log(row.item.registries);
      row.toggleDetails();
      /* if (item.status_registry.value == 0) {
        c.data_di_nascita = item.attributables.DOB;
        c.luogo_di_nascita = this.$store.state.auth.councils.find(
          (e) => e.id == item.attributables.POB
        ).title;
        c.numero_documento = item.attributables.DOCN;
      }
      c.indirizzo = this.toRelationString(
        "correspondences",
        item.correspondences,
        ["ADDR"],
        [0]
      ); // Residenza / Sede Legale = 0
      c.domicilio = this.toRelationString(
        "correspondences",
        item.correspondences,
        ["ADDR"],
        [1]
      ); // Domicilio = 1
      c.indirizzo_alternativo = this.toRelationString(
        "correspondences",
        item.correspondences,
        ["ADDR"],
        [2]
      ); // Indirizzo Alternativo = 2
      let customAttributes = this.convertFromCustomAttributesToFields(
        this.customAttributes()(this.repository)
      );
      Object.keys(customAttributes).forEach((i) => {
        let value = customAttributes[i].key.split(".");
        let label = customAttributes[i].label;
        c[label] = item.attributables[value[value.length - 1]];
      }); */
    },
    getFilteredRelativeTypes(item) {
      return this.relativeTypes.map((relative_type) => {
        return {
          value: relative_type.value,
          text:
            item.status_registry.value === 1
              ? relative_type.male
              : item.attributables.SEX === "M"
              ? relative_type.male
              : relative_type.female,
        };
      });
    },
    hasPrivacyDoc(item) {
      let pds = item.documents?.filter((e) => e.document_type.value === 0);
      return pds.length;
    },
    onModalShow() {
      if (this.tabIndex < this.tabs.length)
        this.setTab(this.tabs[this.tabIndex], this.tabIndex);
    },
    onModalHidden() {
      this.setTab({ name: "General", text: "Generale" }, 0);
    },
    customOpenAppointmentModal(item, index, target) {
      this.tabIndex = 6; // TabIndex ID degli Appuntamenti
      this.openModal(item, index, target);
    },
    customOpenNoteModal(item, index, target) {
      this.tabIndex = 7; // TabIndex ID delle Note
      this.openModal(item, index, target);
    },
    customOpenTodoModal(item, index, target) {
      this.tabIndex = 8; // TabIndex ID delle Attività
      this.openModal(item, index, target);
    },
    customOpenDocModal(item, index, target) {
      this.tabIndex = 10; // TabIndex ID dei Documenti
      this.openModal(item, index, target);
    },
    ...mapGetters("auth", {
      getInsurers: "insurers",
      getRelativeTypes: "relative_types",
      getUsers: "users",
    }),
    onAddNewRegistry() {
      this.$router.push({
        name: `${this.resource}.create`,
        params: {
          /* remove the / character if it is the first character of a string */
          returnUrl: this.$route.path.replace(/^\/(.*)/, "$1"),
        },
      });
    },
  },
  computed: {
    coord() {
      if (this.coordinator && this.coordinator.id) {
        return this.coordinator.status_registry.value === 1
          ? `Capogruppo :: ${this.coordinator.attributables.CNAM}`
          : `Capogruppo :: ${this.coordinator.attributables.SURN} ${this.coordinator.attributables.NAME}`;
      } else {
        return "Capogruppo :: nessuno";
      }
    },
    // getGroupId() {
    //   return this.gId;
    // },
  },
};
</script>
<style lang="scss">
.text-red {
  color: red;
}

:deep(.check) {
  width: 1px;
  // padding-left: 10px;
}

:deep(.check .form-group) {
  margin-bottom: unset !important;
}

:deep(
    table
      tbody
      td.is-coordinator:has(input[type="checkbox"]:checked)
      ~ td.communication-type
      .multiselect__content-wrapper
      li#null-5
  ) {
  display: none;
}
</style>
