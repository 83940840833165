<template>
  <div class="mt-2">
    <!-- <div :style="[!noInnerWidth ? { width: width } : {}]"> -->
    <div :style="{ width: !noInnerWidth ? width : 'auto' }">
      <div>
        <!-- <div class="mb-1">
            <b-row>
              <b-col align="right">
                <b-button
                  class="btn-create"
                  type="button"
                  variant="primary"
                  size="sm"
                  @click="onAddCorrespondence"
                  title="Crea Indirizzo"
                  v-if="canVerb(resource, 'store')"
                  ><b-icon icon="plus-circle" aria-hidden="true"></b-icon>
                  Crea</b-button
                >
              </b-col>
            </b-row>
          </div> -->
        <b-table
          :busy="isLoading"
          head-variant="light"
          :no-local-sorting="true"
          :items="items"
          :fields="getAllFields"
          :current-page="currentPage"
          per-page="0"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :sticky-header="height"
          no-border-collapse
          responsive
          show-empty
          empty-text="Non ci sono informazioni da mostrare"
          small
          @sort-changed="sortingChanged"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <base-icon name="loading" width="35" height="35" />
              <p>Operazione in corso...</p>
            </div>
          </template>
          <template #cell(actions)="row">
            <div class="action-buttons">
              <b-button
                v-if="actions.includes('edit')"
                size="sm"
                variant="lisaweb"
                @click="onEdit(row.item.id)"
                class="mt-1 mr-1"
                title="Modifica"
                :disabled="checkIsImported(row.item)"
              >
                <b-icon icon="pencil-square"></b-icon>
              </b-button>
              <!-- destroy -->
              <b-button
                v-if="
                  actions.includes('destroy') &&
                  [2].includes(row.item.status_correspondence.value)
                "
                size="sm"
                variant="lisaweb"
                @click="onDestroy(row.item.id)"
                class="mt-1 mr-1"
                title="Elimina"
              >
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </template>

          <template #cell(domicilio)="{ item }">
            <span
              v-html="
                toRelationString(
                  'correspondences',
                  item,
                  ['ADDR'],
                  [item.status_correspondence.value]
                )
              "
            ></span>
          </template>

          <template #cell(city)="{ item }" v-if="items.length">
            <!-- {{
                $store.state.auth.councils.find(
                  (e) => e.id == item.attributables.CITY
                ).title
              }} -->
            {{
              item.attributables.CITY2
                ? `${item.attributables.CITY2} - ${
                    $store.state.auth.countries.find(
                      (e) => e.id == item.attributables.NOR
                    ).title
                  }`
                : $store.state.auth.councils.find(
                    (e) => e.id == item.attributables.CITY
                  ).title
            }}
          </template>

          <template #row-details="row">
            <b-card>
              <b-row
                class="mb-2"
                v-for="(value, key) in customized(row.item)"
                :key="key"
              >
                <b-col sm="3" class="text-sm-right"
                  ><b>{{ key | capitalize }}:</b></b-col
                >
                <b-col>{{ value }}</b-col>
              </b-row>
            </b-card>
          </template>
        </b-table>
      </div>
      <b-row>
        <div class="show-text">
          <p>Mostra</p>
        </div>

        <div class="group">
          <b-form-group
            label-for="per-page-select"
            label-cols-sm="3"
            label-cols-md=""
            label-cols-lg="3"
            label-size="sm"
            md="auto"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              style="width: 68px"
              size="sm"
              @change="onPageChange(1)"
              :disabled="!items.length"
            ></b-form-select>
          </b-form-group>
        </div>

        <div class="page">
          <p>di {{ totalItems }} per pagina</p>
        </div>
        <div class="pagination" v-if="totalItems">
          <b-pagination
            v-model="currentPage"
            limit="3"
            :total-rows="totalItems"
            :per-page="perPage"
            size="sm"
            @change="onPageChange"
          ></b-pagination>
        </div>
      </b-row>
    </div>
    <!-- <add-correspondence-modal
      :status_correspondence_allowed="getStatusCorrespondenceAllowed()"
      @create="onCreate"
    ></add-correspondence-modal> -->
  </div>
</template>

<script>
import template from "./template";
import BaseIcon from "@/components/BaseIcon";
// import AddCorrespondenceModal from "@/components/modals/addCorrespondenceModal";
// import FormMixin from "@/mixins/FormMixin";
// // import { showSnackbar } from "@/utils/messages";
export default {
  // mixins: [FormMixin],
  name: "CorrespondencesTable",
  extends: template,
  components: {
    BaseIcon,
    // AddCorrespondenceModal
  },
  data() {
    return {
      // TODO default sorting (removed since 3.0.25.2 cos not working anymore)
      // sortBy: "status_correspondence",
      // sortDesc: false
    };
  },
  methods: {
    checkIsImported(item) {
      let isImported;
      const correspondenceType =
        item.status_correspondence.value === 0 ? true : false;
      if (
        Object.prototype.hasOwnProperty.call(item, "registry") &&
        Object.prototype.hasOwnProperty.call(item.registry, "insurer_id")
      ) {
        isImported = !item.registry.insurer_id ? false : true;
      } else {
        isImported = false;
      }
      return isImported && correspondenceType;
    },
    // getRegistryId() {
    //   let registry_id;
    //   if (this.filterOn && Object.keys(this.filterOn).length) {
    //     registry_id = this.filterOn.byRegistry.id
    //   }
    //   return registry_id
    // },
    // getStatusCorrespondenceAllowed() {
    //   // 0: una e una sola Residenza / Sede Legale, sempre
    //   // 1: nessun o al più un solo Domicilio / Sede Amministrativa
    //   // 2: n Indirizzi Alternaivi, con n >= 0
    //   // devo poter creare indirizzi di tipo 2, e di tipo 1 se non già presenti
    //   let ret = [2];
    //   let all = this.items.map((item) => item.status_correspondence.value)
    //   if (!all.includes(1)) {
    //     ret.push(1);
    //   }
    //   return ret
    // },
    // onAddCorrespondence() {
    //   // open the correspondence create modal
    //   this.$bvModal.show("addCorrespondenceModal");
    // },
    // onCreate(form) {
    //   this.$emit("create", form);
    // },
  },
};
</script>

<style lang="css" scoped></style>
